const ProjectData = (publicUrl) => ({
  'Astra-coloured-diary': {
      images: [{
          id: 1,
          title: 'Astra\'s coloured diary - Page 1',
          url: `${publicUrl}/images/Astra-coloured-diary/1.jpg`
        },
        {
          id: 2,
          title: 'Astra\'s coloured diary - Page 2',
          url: `${publicUrl}/images/Astra-coloured-diary/2.jpg`
        },
        {
          id: 3,
          title: 'Astra\'s coloured diary - Page 3',
          url: `${publicUrl}/images/Astra-coloured-diary/3.jpg`
        },
        {
          id: 4,
          title: 'Astra\'s coloured diary - Page 4',
          url: `${publicUrl}/images/Astra-coloured-diary/4.jpg`
        },
        {
          id: 5,
          title: 'Astra\'s coloured diary - Page 5',
          url: `${publicUrl}/images/Astra-coloured-diary/5.jpg`
        },
        {
          id: 6,
          title: 'Astra\'s coloured diary - Page 6',
          url: `${publicUrl}/images/Astra-coloured-diary/6.jpg`
        },
        {
          id: 7,
          title: 'Astra\'s coloured diary - Page 7',
          url: `${publicUrl}/images/Astra-coloured-diary/7.jpg`
        },
        {
          id: 8,
          title: 'Astra\'s coloured diary - Page 8',
          url: `${publicUrl}/images/Astra-coloured-diary/8.jpg`
        },
        {
          id: 9,
          title: 'Astra\'s coloured diary - Page 9',
          url: `${publicUrl}/images/Astra-coloured-diary/9.jpg`
        },
        {
          id: 10,
          title: 'Astra\'s coloured diary - Page 10',
          url: `${publicUrl}/images/Astra-coloured-diary/10.jpg`
        },
        {
          id: 11,
          title: 'Astra\'s coloured diary - Page 11',
          url: `${publicUrl}/images/Astra-coloured-diary/11.jpg`
        },
        {
          id: 12,
          title: 'Astra\'s coloured diary - Page 12',
          url: `${publicUrl}/images/Astra-coloured-diary/12.jpg`
        },
        {
          id: 13,
          title: 'Astra\'s coloured diary - Page 13',
          url: `${publicUrl}/images/Astra-coloured-diary/13.jpg`
        },
        {
          id: 14,
          title: 'Astra\'s coloured diary - Page 14',
          url: `${publicUrl}/images/Astra-coloured-diary/14.jpg`
        },
        {
          id: 15,
          title: 'Astra\'s coloured diary - Page 15',
          url: `${publicUrl}/images/Astra-coloured-diary/15.jpg`
        },
        {
          id: 16,
          title: 'Astra\'s coloured diary - Page 16',
          url: `${publicUrl}/images/Astra-coloured-diary/16-13092015.jpg`
        },
        {
          id: 17,
          title: 'Astra\'s coloured diary - Page 17',
          url: `${publicUrl}/images/Astra-coloured-diary/17-13092015.jpg`
        },
        {
          id: 18,
          title: 'Astra\'s coloured diary - Page 18',
          url: `${publicUrl}/images/Astra-coloured-diary/18-13092015.jpg`
        },
        {
          id: 19,
          title: 'Astra\'s coloured diary - Page 19',
          url: `${publicUrl}/images/Astra-coloured-diary/19-13092015.jpg`
        },
        {
          id: 20,
          title: 'Astra\'s coloured diary - Page 20',
          url: `${publicUrl}/images/Astra-coloured-diary/20-13092015.jpg`
        },
        {
          id: 21,
          title: 'Astra\'s coloured diary - Page 21',
          url: `${publicUrl}/images/Astra-coloured-diary/21-13092015.jpg`
        },     {
          id: 22,
          title: 'Astra\'s coloured diary - Page 22',
          url: `${publicUrl}/images/Astra-coloured-diary/page22.jpg`
        },     {
          id: 23,
          title: 'Astra\'s coloured diary - Page 23',
          url: `${publicUrl}/images/Astra-coloured-diary/page23.jpg`
        }
      ],
    title: 'Astra\'s coloured diary',
  },
  'Astra-paper-stars': {
    images: [{
        id: 1,
        title: '',
        url: `${publicUrl}/images/Astra-paper-stars/ster_1.jpg`
      },
      {
        id: 2,
        title: '',
        url: `${publicUrl}/images/Astra-paper-stars/ster_2.jpg`
      },
      {
        id: 3,
        title: '',
        url: `${publicUrl}/images/Astra-paper-stars/ster_3.jpg`
      },
      {
        id: 4,
        title: '',
        url: `${publicUrl}/images/Astra-paper-stars/ster_4.jpg`
      },
      {
        id: 5,
        title: '',
        url: `${publicUrl}/images/Astra-paper-stars/ster_5.jpg`
      },
      {
        id: 6,
        title: '',
        url: `${publicUrl}/images/Astra-paper-stars/ster_6.jpg`
      },
      {
        id: 7,
        title: '',
        url: `${publicUrl}/images/Astra-paper-stars/ster_7.jpg`
      },
      {
        id: 8,
        title: '',
        url: `${publicUrl}/images/Astra-paper-stars/ster_8.jpg`
      }
    ],
    title: 'Astra\'s paper stars',
  },
  'Astra-sketches': {
    images: [{
        id: 1,
        title: '',
        url: `${publicUrl}/images/Astra-sketches/schets_0.jpg`
      },
      {
        id: 2,
        title: '',
        url: `${publicUrl}/images/Astra-sketches/schets_1.jpg`
      },
      {
        id: 3,
        title: '',
        url: `${publicUrl}/images/Astra-sketches/schets_2.jpg`
      },
      {
        id: 4,
        title: '',
        url: `${publicUrl}/images/Astra-sketches/schets_3.jpg`
      },
      {
        id: 5,
        title: '',
        url: `${publicUrl}/images/Astra-sketches/schets_4.jpg`
      },
      {
        id: 6,
        title: '',
        url: `${publicUrl}/images/Astra-sketches/schets_5.jpg`
      },
      {
        id: 7,
        title: '',
        url: `${publicUrl}/images/Astra-sketches/schets_8.jpg`
      },
      {
        id: 8,
        title: '',
        url: `${publicUrl}/images/Astra-sketches/schets_9.jpg`
      },
      {
        id: 8,
        title: '',
        url: `${publicUrl}/images/Astra-sketches/schets_10.jpg`
      },
      {
        id: 8,
        title: '',
        url: `${publicUrl}/images/Astra-sketches/schets_11.jpg`
      },
      {
        id: 8,
        title: '',
        url: `${publicUrl}/images/Astra-sketches/schets_12.jpg`
      }
    ],
    title: 'Astra\'s sketches',
  },
  'Astra-Blue-Print': {
    images: [
      {
        id: 1,
        title: 'Blue print - 2008',
        url: `${publicUrl}/images/Astra-coloured-diary/23-blue-print.jpg`
      }
    ],
    title: 'Astra\'s Blue Print',
  }
})
export default ProjectData
