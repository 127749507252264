import React from "react";
import {
  Link
} from "react-router-dom";
import './Header.css';

const Header = () => (
  <nav className="header">
    <ul>
      <li>
        <Link className="link__astra" to="/">Astra</Link>
      </li>
      <li>
        <Link className="link__archive" to="/archief">Archief</Link>
      </li>

      {
      /*
        <li>
          <Link className="link__info" to="/info">Info</Link>
        </li>
      */
      }
      <li>
        <a className="link__social" href="https://www.instagram.com/annerieke_bakker/">Social Media</a>
      </li>
      <li>
        <a className="link__contact" href="mailto:contact@anneriekebakker.nl">Contact</a>
      </li>
    </ul>
  </nav>
)

export default Header
