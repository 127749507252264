import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import Header from './components/Header'
import Homepage from './pages/Homepage'
import Project from './pages/Project'
import Archive from './pages/Archive'
import ArchivedProject from './pages/ArchivedProject'

function App() {
  return (
    <Router>
      <div>
        <Header />

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/project">
            <Project />
          </Route>
          <Route path="/archief/project">
            <ArchivedProject />
          </Route>
          <Route path="/archief">
            <Archive />
          </Route>
          <Route path="/">
            <Homepage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
