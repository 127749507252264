const ProjectData = (publicUrl) => ({
  'Trinity': {
    images: [
      {
        id: 1,
        title: 'Trinity for Sophia',
        url: `${publicUrl}/images/Trinity/sophia1.jpg`,
      },
      {
        id: 2,
        title: 'Trinity for Sophia (2)',
        url: `${publicUrl}/images/Trinity/sophia2.jpg`,
      },
      {
        id: 3,
        title: 'Model for Trinity',
        url: `${publicUrl}/images/Trinity/model.jpg`,
      },
      {
        id: 4,
        title: 'Untitled',
        url: `${publicUrl}/images/Trinity/5-in-a-row.jpg`,
      },
      {
        id: 5,
        title: 'Trinity\'s Series - Part 1',
        url: `${publicUrl}/images/Trinity/1.jpg`,
      },
      {
        id: 6,
        title: 'Trinity\'s Series - Part 2',
        url: `${publicUrl}/images/Trinity/2.jpg`,
      },
      {
        id: 7,
        title: 'Trinity\'s Series - Part 3',
        url: `${publicUrl}/images/Trinity/3.jpg`,
      },
      {
        id: 8,
        title: 'Trinity\'s Series - Part 4',
        url: `${publicUrl}/images/Trinity/4.jpg`,
      },
      {
        id: 9,
        title: 'Trinity\'s Series - Part 5',
        url: `${publicUrl}/images/Trinity/5.jpg`,
      },
      {
        id: 10,
        title: 'Trinity\'s Series - Part 6',
        url: `${publicUrl}/images/Trinity/6.jpg`,
      },
      {
        id: 11,
        title: 'Trinity\'s Series - Part 7',
        url: `${publicUrl}/images/Trinity/7.jpg`,
      },
      {
        id: 12,
        title: 'Trinity\'s Series - Part 8',
        url: `${publicUrl}/images/Trinity/8.jpg`,
      },
      {
        id: 13,
        title: 'Trinity\'s Series - Part 9',
        url: `${publicUrl}/images/Trinity/9.jpg`,
      },
      {
        id: 14,
        title: 'Trinity\'s Series - Part 10',
        url: `${publicUrl}/images/Trinity/10.jpg`,
      },
      {
        id: 15,
        title: 'Trinity\'s Series - Part 11',
        url: `${publicUrl}/images/Trinity/11.jpg`,
      },
      {
        id: 16,
        title: 'Trinity\'s Series - Part 12',
        url: `${publicUrl}/images/Trinity/12.jpg`,
      },
      {
        id: 17,
        title: 'Trinity\'s Series - Part 13',
        url: `${publicUrl}/images/Trinity/13.jpg`,
      },
      {
        id: 18,
        title: '',
        type: 'text',
        text: 'Trinity´s series is a work that refers to a concept from Christianity. In Christianity “The Trinity” means  that one God exists in three persons. (Father, Son, Holy Ghost) In this series Trinity is a character that shows different stadia of  growth.  For now there are four stadia which show the development of Trinity, namely, from birth to young adolescent. It also stands for the development of art.<br><br><br>----------------------------------------------<br><br><br>Trinity’s series verwijst naar een concept uit het Christendom. In het Christendom laat de “The Trinity” zien dat God bestaat uit drie personen. (Vader, Zoon en de Heilige Geest.) In deze serie is Trinity een personage dat verschillende stadia van de groei laat zien. Voor nu zijn er vier stadia. Deze stadia laten de ontwikkeling zien van Trinity. Namelijk, vanaf de geboorte naar jong volwassen. Trinity laat ook de ontwikkeling van kunst zien.'

      },
      {
        id: 19,
        title: 'Trinity\'s Series - Post',
        url: `${publicUrl}/images/Trinity/trinity-post.jpg`,
      },
      {
        id: 20,
        title: 'Trinity\'s Series - Duplex Amauris Niavius',
        url: `${publicUrl}/images/Trinity/16.jpg`,
      },
      { id: 21,
        title: 'Trinity\'s Series - Duplex Amauris Niavius abstract',
        url: `${publicUrl}/images/Trinity/17.jpg`,
      },
      { id: 22,
        title: 'Trinity\'s Series - Araschnia levana - changing condition',
        url: `${publicUrl}/images/Trinity/Araschnia-levana.jpg`,
      }
    ],
    title: 'Trinity\'s Series',
  },
  'Sterre': {
    images: [{
        id: 1,
        title: 'Untitled - 2009',
        url: `${publicUrl}/images/Sterre/1.jpg`
      },
      {
        id: 2,
        title: 'Untitled - 2009',
        url: `${publicUrl}/images/Sterre/2.jpg`
      },
      {
        id: 3,
        title: 'Untitled - 2013',
        url: `${publicUrl}/images/Sterre/3.jpg`
      },
      {
        id: 4,
        title: 'Untitled - 2015',
        url: `${publicUrl}/images/Sterre/water-collage.jpg`
      },
      {
        id: 5,
        title: 'Untitled - 2015',
        url: `${publicUrl}/images/Sterre/water-collage-ingezoomd.jpg`
      },
      {
        id: 6,
        title: 'Untitled - 2015',
        url: `${publicUrl}/images/Sterre/water-collage-enkel.jpg`
      },
      {
        id: 11,
        type: 'youtube',
        title: '',
        youtubeUrl: 'https://www.youtube.com/embed/IrO2-ImfpZs',
      }
      // {
      //   id: 11,
      //   type: 'youtube',
      //   title: '',
      //   youtubeUrl: $sce.trustAsResourceUrl('https://www.youtube.com/embed/SzQWfGqud9Q?playlist=DEbKue5BNgw,IrO2-ImfpZs&rel=0&showinfo=1'),
      // }
    ],
    title: 'Sterre',
  },
  'Hebe': {
    images: [
      {
        id: 1,
        title: 'Hebe sunflowers',
        url: `${publicUrl}/images/Hebe/1.jpg`,
      },
      {
        id: 2,
        title: 'Untitled 2007 - 2007',
        url: `${publicUrl}/images/Hebe/2.jpg`,
      },
      {
        id: 3,
        title: 'Still life of sunflowers - 2010',
        url: `${publicUrl}/images/Hebe/3.jpg`,
      },
      {
        id: 4,
        title: 'Hebe\'s daily tasks - sunflowers - 2010',
        url: `${publicUrl}/images/Hebe/4-sunflowers.jpg`,
      },
      {
        id: 5,
        title: 'Hebe\'s daily tasks - ironing for the Graces - 2010',
        url: `${publicUrl}/images/Hebe/5-ironing.jpg`,
      },
      {
        id: 6,
        title: 'Hebe for the Garden',
        url: `${publicUrl}/images/Hebe/6.jpg`,
      },
      {
        id: 10,
        title: 'Untitled - 2013 - 2016',
        url: `${publicUrl}/images/Hebe/rode-collage.jpg`,
      },
      {
        id: 11,
        title: 'Untitled - 2013 - 2016',
        url: `${publicUrl}/images/Hebe/rode-collage-zoom1.jpg`,
      },
      {
        id: 12,
        title: 'Untitled - 2013 - 2016',
        url: `${publicUrl}/images/Hebe/rode-collage-zoom2.jpg`,
      },
      {
        id: 13,
        title: 'Untitled - 2013 - 2016',
        url: `${publicUrl}/images/Hebe/detail3.jpg`,
      },
      {
        id: 14,
        title: 'Hebe for hybrid',
        url: `${publicUrl}/images/Hebe/14-2.jpg`,
      },
      {
        id: 15,
        title: 'Hebe for hybrid detail',
        url: `${publicUrl}/images/Hebe/15.jpg`,
      },
      {
        id: 16,
        type: 'youtube',
        title: 'hebe\'s time lapses part1 hibiscus',
        youtubeUrl: 'https://www.youtube.com/embed/Ifz6GogUlis',
      }
    ],
    title: 'Hebe',
  }
})
export default ProjectData
