import React from "react";
import {
  Route,
  Switch,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import './Project.css';
import YoutubeEmbed from '../components/YoutubeEmbed'

import ProjectDataFn from './ArchivedProjectData'

const publicUrl = process.env.PUBLIC_URL
const projectData = ProjectDataFn(publicUrl)

const Project = () => {
  const { projectId } = useParams();
  if (projectData[projectId]) {
    const data = projectData[projectId]

    return (
      <div className="project">
        {data.images.map((image) => {
          if (image.type === 'youtube') {
            return <YoutubeEmbed src={image.youtubeUrl} key={`${image.title} - ${image.id}`} title={`${image.title} - ${image.id}`} />
          } else {
            return <img src={image.url} alt={image.title} key={`${image.title} - ${image.id}`}/>
          }
        })}
      </div>
    )
  }
  return <span>{projectId}</span>

}

const ProjectRoute = ({ projectId }) => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:projectId`}>
        <Project />
      </Route>
    </Switch>
  );
}

export default ProjectRoute
