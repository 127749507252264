import React from "react";
import './Header.css';

const YoutubeEmbed = ({ src, title }) => (
  <div className="youtube__container">
    <iframe title={title} width="640" height="480" src={src} frameBorder="0" allowFullScreen></iframe>
  </div>
)

export default YoutubeEmbed
