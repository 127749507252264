import React from "react";
import {
  Link,
} from "react-router-dom";
import './Homepage.css';

const publicUrl = process.env.PUBLIC_URL

const offsetLeft = function() {
  const maxOffsetLeft = 200;

  return Math.floor(Math.random() * maxOffsetLeft);
};

const offsetTop = function() {
  const maxOffsetTop  = 100;

  return Math.floor(Math.random() * maxOffsetTop);
};

const projects = [
  [
    {
      id: "Hebe",
      previewUrl: `${publicUrl}/images/Hebe/front.jpg`,
      offsetLeft: offsetLeft(),
      offsetTop: offsetTop(),
    },
    {
      id: "Trinity",
      previewUrl: `${publicUrl}/images/Trinity/trinityfront.jpg`,
      offsetLeft: offsetLeft(),
      offsetTop: offsetTop(),
    },
  ],
  [
    {
      id: "Sterre",
      previewUrl: `${publicUrl}/images/Sterre/front.jpg`,
      offsetLeft: offsetLeft(),
      offsetTop: offsetTop(),
    },
  ]
];

const styleForProject = (project) => ({
  left: `${project.offsetLeft}px`,
  top: `${project.offsetTop}px`,
  width: '50%',
  position: 'relative',
})

const Projects = () => {
  return (
    <div className="homepage">
      {projects.map((row, index) => {
        return (
          <div key={index} className={`row row-${index}`}>
            {row.map((project) => {
              return (
                <div key={project.id} className={`homepage-project col-md-6 col-sm-6 col-xs-9`} style={styleForProject(project)}>
                  <Link to={`/archief/project/${project.id}`}><img className="preview" src={project.previewUrl} alt={project.id}></img></Link>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Projects
